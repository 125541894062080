.pageNotFound {
    @include wrap;
    text-align: center;
    background-color: #ED4D2D;
    color: white;
    padding: 55px;
    h3 {
        font-size: 18px;
    }
    img {
        margin: 15px;
    }
}