.info {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 160px 0px;
    &__flexed {
        @include flex-cAlign;
        flex-direction: column;
        width: 250px;
        text-align: center;
        h2 {
            font-size: 26px;
        }
        & > img {
            width: 250px;
        }
    }
    @media(max-width: 1024px) {
        flex-direction: column;
        align-items: center;
    }
}