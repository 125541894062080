.forgetPassword {
    @include flex-cAlign;
    flex-direction: column;
    max-width: 1600px;
    margin-left: auto;
    margin-right: auto;
    &__header {
        @include flex-cJustify;
        width: 100%;
        height: 100vh;
        &__text {
            @include flex-cAlign-cJustify;
            flex-direction: column;
            width: 70%;
            position: relative;
            background: $shopeeOrange;
            color: white;
            overflow: hidden;
            h2 {
                flex-grow: 1;
                margin-top: 20vh;
            }
            img {
                max-width: 80vh;
                min-width: 830px;
            }
        }
        &__form {
            width: 30%;
            @include flex-cAlign;
            flex-direction: column;
            padding: 50px 50px 0px 50px;
            overflow: auto;
            h2 {
                color: $shopeeOrange;
                width: 220px;
                text-align: center;
            }
            label {
                font-weight: bold;
            }
            img { width:  64px; }
            & > div {
                width: 100%;
                margin-top: 25px;
            }

            a, button {
                width: 100%;
            }

            a {
                margin: 25px 0px;
                color: #9C9C9C;
            }

            & > p {
                margin: 45px 0px 15px 0px;
            }

            .sButton {
                margin-top: 30px;
            }
        }
    }

    &__body {
        width: 100%;
        &__video {
            margin: 80px 0px;
            text-align: center;
        }
    }

    @media(max-width: 1024px) {
        &__header {
            height: auto;
            flex-direction: column;
            &__text, &__form {
                height: auto;
                width: auto;
            }
        }
        &__body {
            &__texts {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}