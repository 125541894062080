.footer {
    background: #E5E5E5;
    padding: 70px 0px;
    &__columns {
        @include flex-cJustify;
        ul {
            margin: 0px 80px;
        }
        ul, li {
            list-style: none;
            padding: 0px;
        }
        h2 {
            font-size: 18px;
            font-weight: 100;
            margin-bottom: 20px;
        }
        li {
            margin: 5px;
        }
        li, a {
            color: #9C9C9C;
            text-decoration: none;
        }
        img {
            margin-right: 5px;
        }
    }
    p {
        text-align: center;
        margin-top: 70px;
        color: #9C9C9C;
    }

    @media(max-width: 1024px) {
        &__columns {
            flex-direction: column;
            ul {
                margin: 20px 0px;
            }
        }
        padding: 70px 35px;
    }
}