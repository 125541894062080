.feedback {
    display: flex !important;
    align-items: center;
    textarea {
        height: 20vh;
        display: block;
        margin-right: auto;
        margin-left: auto;
        padding: 2vh 2vh;
        font-family: monospace;
        box-sizing: border-box;
        width: 100%;
        border-width: 2px;
        resize: none;
        &:focus {
            box-shadow: none;
            border-color: black;
        }
    }
    .modal-title {
        @include flex-cAlign;
        img { margin-right: 10px; }
        font-size: 22px;
    }
    .modal-footer {
        button:first-of-type {
            padding: 0.5rem 1rem;
            border-radius: 8px;
            font-size: 1.25rem;   
        }
    }
}