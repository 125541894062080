.prestoDocu {
    @include wrap;
    &__body {
        padding: 4vh 8vw;
    }
    &__list {
        display: flex;
        width: 100%;
        padding: 4vh 0px;
        & > div {
            flex-grow: 1;
        }
        .row {
            margin-right: -15px;
            margin-left: -15px;
            a {
                width: auto;
                color: #9C9C9C;
                padding-bottom: 1vh;
                text-decoration: none;
                &:hover {
                    color: $shopeeOrange;
                }
            }
        }
    }
}