.prestoLessons {
    .subheader {
        height: 320px;
        h1 {
            font-size: 40px !important;
        }
        & > div:first-child {
            @include flex-cJustify;
            flex-direction: column;
        }
        background: linear-gradient(92.07deg, #ED892D 20.1%, #FFB45C 100%);
        box-shadow: 0px 8px 16px #ed4d2d40;
    }

    @media(max-width: 1024px) {
        .subheader {
            & > div:first-child {
                width: 100%;
            }
        }
        .progress {
            width: 110px !important;
            margin-right: 25px !important;
        }
    }
}