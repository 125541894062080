.lessonLayout {
    display: flex;
    @include wrap;
    padding: 4vh 6vw;
    &__outline {
        & > h2 {
            font-size: 20px;
            width: 100%;
            img {
                height: 32px;
                margin-right: 8px;
                padding-bottom: 5px;
            }
            margin-bottom: 18px;
        }
        .nav-item {
            border: 1px solid rgba(0,0,0,.125);
            width: 100%;
            &:not(.nav-item:last-child) {
                border-bottom: 0px;
            }
            a {
                text-decoration: none;
                color: #9C9C9C;
                word-break: break-word;
            }
            .nav-link.active {
                background: $shopeeOrange;
            }
        }
        flex-direction: column;
        width: 25%;
    }
    &__content {
        flex-grow: 1;
        width: 75%;
        padding: 0px 35px;
        img {
            display: block;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            margin-bottom: 15px;
        }
        h3 {
            font-size: 20px;
        }
        h2 {
            span {
                font-size: 24px;
            }
        }
    }
    &__paginate {
        display: flex;
        justify-content: space-between;
        margin-top: 32px;
        button {
            font-size: 1.25rem;
        }
    }
    .lesson-topic__code, .code-format {
            white-space: pre-line;
            background-color: #f1f1f1;
            padding: 18px;
            border-radius: 16px;
    }    
    .shopee-orange {
        color: $shopeeOrange;
        font-weight: bold;
    }
    .hidden {
        opacity: 0;
        z-index: -1;
    }

    @media(max-width: 1024px) {
        flex-direction: column;
        align-items: center;
        &__outline, &__content {
            width: 90%;
        }
        &__content {
            padding: 20px 0px;
            p {
                word-break: break-word;
            }
        }
    }
}