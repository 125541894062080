.sButton {
    &, &:active, &:focus {
        background: $shopeeOrange;
        border: $shopeeOrange;
        color: white;
        border-radius: 8px;
        box-shadow: 0px 14px 16px #eb4c2d40 !important;
        padding: 0.5rem 1rem;
        font-size: 1.25rem;   
    } 
    &:hover {
        background-color: #CB3B1E;
    }
    &:disabled {
        color: #fff;
        background-color: #ED4D2D;
        border-color: #ED4D2D;
    }

    &.signUp {
        background: #6C757D;
        border: #6C757D;
        box-shadow: 0px 14px 16px #545b623d;
        &:hover {
            background-color: #545b62;
        }
    }
}