.dashboard {
    @include wrap;
    &__courses {
        &__container {
            display: flex;
            justify-content: space-around;
        }
    padding: 70px 40px 110px 40px;        
    h2 {
            font-size: 28px;
            img {
                height: 32px;
                margin-right: 8px;
                padding-bottom: 5px;
            }
            margin-bottom: 18px;

        }
    }
    &__course {
        border-radius: 8px;
        box-shadow: 0px 4px 16px #9c9c9c59;
        border-color: transparent;
        display: inline-block;
        text-decoration: none;
        img {
            width: 325px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
        & > div {
            padding: 20px;
            h2 { 
                font-size: 16px; 
                color: black;
            }
            p {
                color: $grey;
                margin: 0px;
            }
        }
        &:hover {
            transform: translateY(-4px);
            transition: all ease .2s;
        }
    }
    &__whatchuthink {
        @include flex-cJustify;
        background-color: #e6e6e6;
        padding: 50px;
        &__card {
            width: 70%;
            img { height: 190px; }
            @include flex-cAlign;
            background-color: #FFFCFA;
            padding: 25px 50px;
            border-radius: 16px;
            box-shadow: 0px 4px 16px #9c9c9c59;
            & > div {
                flex-grow: 1;
                text-align: center;
                padding: 0px 140px;
            }
        }
    }
    .info {
        margin: 80px 0px;
    }

    @media(max-width: 1024px) {
        .subheader {
            & > div:first-child {
                width: auto;
                padding: 8vh 6vw 2vh 6vw;
            }
            flex-direction: column;
            align-items: center;
        }
        &__courses {
            padding: 70px 25px 110px 25px;
            &__container {
                flex-wrap: wrap;
                width: 100%;
            }
        }
        &__course {
            margin: 20px;
        }
        &__whatchuthink {
            padding: 25px 0px;
            &__card {
                flex-direction: column;
                img {
                    width: 100%;
                    height: auto;
                }
                & > div {
                    padding: 0px;
                    margin-top: 10px;
                }
            }
        }
    }
}