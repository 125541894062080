.quiz {
    @include wrap;
    &--hide {
        .quiz__body {
            display: none !important;
        }
        .subheader {
            min-height: calc(100vh - (79px + 385px));
            & > div:nth-child(2) {
                width: 45%;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            & > div:first-child {
                @include flex-cJustify;
                flex-direction: column;
            }
            button:disabled {
                background: white;
                border: white;
                svg {
                    width: 50%;
                }
            }
            & > div:first-child > div > p:last-child {
                margin-top: 15px;
            }
        }
    }
    &__body {
        &.loading {
                // i just eyed this tbh
                height: 50vh;
                @include flex-cAlign-cJustify;
            }
        &__test {
            display: flex;
            h2 {
                font-size: 1.5rem;
                font-weight: bold;
            }
            .quiz-score {
                text-align: center;
                div {
                    justify-content: space-between;
                    display: flex;
                }
                p {
                    margin: 0px;
                    &:first-of-type {
                        margin-top: 80px;
                        font-size: 1.25rem;
                    }
                    &:nth-child(2) {
                        font-size: 2.5rem;
                        color: $shopeeOrange;
                        font-weight: bold;
                    }
                    &:nth-child(3) {
                        margin-bottom: 100px;
                    }
                }
            }
            &__textarea {
                &:not(.hasAnswer) {
                    .submit-answer { 
                        display: none; 
                    }
                    .view-output {
                        text-align: right;
                    }
                }
                &.hasAnswer:first-of-type {
                    & > div:first-of-type {
                        display: flex;
                        justify-content: space-between;
                    }
                    .view-output {
                        background: #6c757d;
                    }
                }
                
                & > div {
                    margin: 25px 0px 40px 0px;
                }

                & > p:first-of-type {
                    margin-bottom: 0.5rem;
                    font-family: inherit;
                    font-weight: 500;
                    line-height: 1.2;
                    color: inherit;
                    font-size: 1.5rem;
                }
                textarea { 
                    padding: 2vh 2vh;
                    font-family: monospace;
                    box-sizing: border-box;
                    height: 50vh;
                    width: 100%;
                    border-radius: 4px;
                    border-width: 2px;
                    resize: none;
                    &:focus {
                        box-shadow: none;
                        border-color: black;
                    }
                }
            }
            &__output {
                background-color: #ececec;
                .icon-loading {
                    margin: 10px 0px;
                  }
                h2 {
                    font-size: 1.5rem;
                    font-weight: bold;
                }
                & > div {
                    width: 100%;
                    max-height: 350px;
                    overflow: auto;
                    margin: 20px 0px;
                }
                table {
                    border: transparent;
                    font-family: monospace;
                    background-color: #FFFCFA;
                    border-radius: 8px !important;
                    max-height: 350px;
                    width: 100%;
                    th {
                        color: $shopeeOrange !important;
                        font-weight: bold;
                        border-bottom: 1px solid #ddd; 
                        text-align: center;
                        padding: 8px;
                    }
                    td {
                        text-align: center;
                        padding: 8px;
                    }
                    & > :not(:first-child) {
                        border-top: 1px solid #ddd;
                    }
                }
            }
            & > div {
                width: 50%;
                padding: 3vh 4vw;
            }
        }
        &__footer {
            padding: 3vh 4vw;
            color: white;
            background: #646464;
            & > div {
                display: flex;
            }
        }
    }
}