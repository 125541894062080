.about {
    @include wrap;
    &__button, &__button:hover, &__button:focus{
        border-color: white;
        color: $shopeeOrange;
        background-color: white;
        margin-top: 25px;
    }
    a:hover {
        color: $shopeeOrange;
    }
    a {
        text-decoration: none;
        color: #9C9C9C;
    }
    .info{
        margin: 70px 0px;
    }
    .subheader{
        & > div:first-child{
            width: 45%;
            height:550px;
        }
        img {
            max-width: 280%;
        }
    }
    h4{
        flex-direction:row;
        text-align: center;
        align-items: flex-start;
        margin-top:0px;
        margin-bottom:50px;
        justify-content: center;
    }
}