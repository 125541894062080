.navigation {
    padding: 18px 16px;
    justify-content: space-between;
    box-shadow: 0px 4px 16px #7a727280;
    position: sticky;
    a:hover {
        color: $shopeeOrange;
    }
    button {
        &, &:hover, &:active {
            color: $shopeeOrange;
            border: none;
            background: none;
            box-shadow: none;
            font-weight: bold;
        }
    }
    &, &__left, &__right, a {
        @include flex-cAlign;
    }
    &__left {
        a {
            color: $grey;
            text-decoration: none;
            img { padding-bottom: 10px; }
            &:first-of-type {
                color: $shopeeOrange;
            }
            h1 {
                font-size: 18px;
                margin-bottom: 0px;
                margin: 0px 0.5rem; 
                font-weight: bold;
            }
            margin: 0px 0.5rem; 
        }
    }
    &__right {
        p { margin: 0px 8px }
    }

    @media(max-width: 1024px) {
        &__right {
            display: none;
        }
        &__left {
            a:not(:first-child) {
                display: none;
            }
        }
    }
}