.lessonList {
  @include wrap;
  padding: 4vh 6vw;
  &__header {
    display: flex;
    margin-bottom: 20px;;
    & > div {
      @include flex-cAlign;
      h2 {
        font-size: 1.25rem;
        margin-left: 0.5em;
        margin-bottom: 0px;;
      }
      &:first-child {
        width: 30%;
        img {
          width: 24px;
          height: 24px;
        }
      }
      &:nth-child(2) {
        justify-content: flex-end;      
        width: 70%;
        .progress {
          width: 620px;
          margin-right: 100px;
        }
        p {
          font-weight: bold;
          margin: 0px;
        }
      }
    }
  }
  &__body {
    padding: 10px 60px;
    &__quizes {
      margin-left: 20px;
      margin-bottom: 35px;
      padding: 12px;
      a {
        text-decoration: none;
        color:#9C9C9C !important;
        font-weight: bold;
        margin-left:10px;
      }
      a:hover {
        color: $shopeeOrange !important;
        cursor: pointer;
      }
      &__locked {
        margin-left: 5px;
      }
    }
  }
  &__lesson {
      text-decoration: none;
      font-size: 1rem;
      font-weight: 500;
      &, &:hover {
        color: $shopeeOrange;
      }
    p {
      border-radius: 8px;
      box-shadow: 0px 4px 16px #9c9c9c59;
      border-color: transparent;
      padding: 15px 20px;
      &:hover {
        transform: translateY(-4px);
        transition: all ease .2s;
      }
    }
  }
}