.subheader {
    background: $shopeeOrange;
    color: white;
    display: flex;
    @include wrap;
    img {
        max-width: 520px;
    }
    & > div:first-child {
        width: 55%;
        padding: 8vh 6vw;
        h1 {
            font-size: 32px;
        }
        p {
            padding-right: 40px;
        }
    }
    & > div:nth-child(2) {
        width: 45%;
        @include flex-cJustify;
        align-items: flex-end;
    }
}